<template>
  <div>
    <template v-if="!submitted">
    <v-form>
      <v-container fluid>
        <v-row class="justify-center">
            <v-col class="pl-7" cols="2">
                <h2>Request Password Reset</h2>
            </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pb-2 pt-0 mt-0">
            <v-text-field v-model="email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pt-0 mt-0 offset-xl-1">
            <router-link :to="'/login'">Back To Login</router-link>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="offset-md-1" cols="2">
            <v-btn color="primary" :disabled="!canSubmit" @click="handleSubmit">
                Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    </template>
    <template v-else>
      <v-container fluid>
        <v-row class="justify-center">
            <v-col class="pl-15" cols="5">
                <h1>Your password reset request has been submitted!</h1>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col class="" cols="4">
                <span style="font-size: 20px">An email has been sent to your email. Please follow the link provided in the email for further instructions.</span>
            </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      submitted: false,
      isSubmitting: false,
    };
  },
  computed: {
    canSubmit() {
      return this.email !== '' && !this.isSubmitting;
    },
  },
  methods: {
    ...mapActions('account', ['requestPasswordReset']),
    handleSubmit() {
      this.isSubmitting = true;
      this.requestPasswordReset({ email: this.email }).then(() => {
        this.submitted = true;
        this.isSubmitting = false;
      }).catch(() => {
        this.isSubmitting = false;
      });
    },
  },
  created() {

  },
};
</script>
